import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
          <a
            href="#main-content"
            className="element-invisible element-focusable"
          >
            Informationen zum Thema Durchfall
          </a>
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/norovirus"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf active-trail">
                        <a
                          href="/norovirus/"
                          title
                          className="active-trail active"
                        >
                          Norovirus
                        </a>
                    </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/norovirus"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Norovirus</h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/header_visuals_695x286_norovirus.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Die meisten viralen Magen-Darm-Entzündungen werden
                            durch Noroviren verursacht. Wenn sich viele Menschen
                            auf begrenztem Raum zusammenfinden, können sich
                            Noroviren sehr schnell verbreiten.
                            Kinderbetreuungsstätten, Altenheime, Krankenhäuser,
                            öffentliche Transportmittel und Schulen sind Orte,
                            an denen sich viele Menschen anstecken. Die Zeit
                            zwischen Ansteckung und dem Auftreten der Symptome
                            kann zwischen einigen Stunden und zwei Tagen
                            betragen.{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>Symptome</h3>
                              <p>
                                Betroffene können unter krampfartigen
                                Bauchschmerzen, Durchfall, Übelkeit und starkem
                                Erbrechen leiden. Zu einer echten Lebensgefahr
                                kann der starke Flüssigkeitsverlust infolge von
                                Erbrechen und Durchfall für Kinder sowie ältere
                                und geschwächte Menschen werden. Normalerweise
                                verläuft die Erkrankung aber eher harmlos. Die
                                Symptome klingen nach spätestens drei Tagen ab
                                und es bleiben keine dauerhaften Folgen zurück.
                              </p>
                              <h3>Übertragung</h3>
                              <p>
                                Mit Noroviren kann man sich durch direkten
                                Körperkontakt mit einem Erkrankten, aber auch
                                durch die gemeinsame Nutzung von Gegenständen
                                anstecken. Die Viren überleben unter anderem
                                auf&nbsp; Handtüchern, WC-Sitzen oder Türklinken
                                und für einige Minuten sogar Temperaturen von
                                +60 Grad Celsius. Selbst wenn die akute Phase
                                der Erkrankung vorüber ist, kann man sich noch
                                bis zu zwei weitere Tage anstecken
                                beziehungsweise andere infizieren. Im Stuhl
                                lassen sich die Viren sogar noch Wochen nach der
                                Infektion nachweisen.&nbsp;
                              </p>
                              <h3>Behandlung</h3>
                              <p>
                                Um dem starken Flüssigkeitsverlust zu begegnen,
                                sollten Sie unbedingt ausreichend trinken.
                                Elektrolytmischungen aus der Apotheke oder
                                Drogerie eigenen sich, um dem Körper die
                                entzogenen Elektrolyte wieder zuzuführen.
                                Rezeptfreie Mittel gegen Durchfall sowie
                                Anti-Brechmittel erhalten Sie ebenfalls in Ihrer
                                &nbsp;der Apotheke.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>Vorbeugung</h3>
                              <p>
                                Gegen das Norovirus kann man sich nicht impfen
                                lassen. Nur die Beachtung von Hygieneregeln kann
                                Schutz vor einer Ansteckung bieten:
                              </p>
                              <ul>
                                <li>
                                  Achten Sie darauf, sich vor der Zubereitung
                                  beziehungsweise dem Verzehr von Essen und nach
                                  jedem Toilettengang sorgfältig die Hände zu
                                  waschen
                                </li>
                                <li>
                                  Lebensmittel wie Obst, Gemüse und Salat
                                  sollten Sie gründlich säubern
                                </li>
                                <li>
                                  Beachten Sie die Garzeit von Lebensmitteln
                                </li>
                                <li>
                                  Wenn Sie sich mit dem Norovirus angesteckt
                                  haben, versuchen Sie bis zwei Tage nach Ende
                                  der akuten Phase den Kontakt mit anderen auf
                                  einem Minimum zu beschränken
                                </li>
                                <li>
                                  Um die Übertragungsmöglichkeiten zu
                                  reduzieren, sollten Kranke eigene Handtücher,
                                  Kosmetikartikel und - sofern die Möglichkeit
                                  besteht - eine eigene Toilette benutzen. Für
                                  die Reinigung des Geschirrs sollten Sie das
                                  Intensivprogramm der Spülmaschine wählen.
                                </li>
                                <li>
                                  Die Bettwäsche, Handtücher und Kleidung der
                                  Erkrankten sollten Sie bei mindestens 60 Grad
                                  Celsius waschen.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
                <div id="block-block-8" className="block block-block">
                  <div className="content">
                    <h3>Welches IMODIUM® ist das Richtige für mich?</h3>
                    <p>
                      <img
                        alt
                        src="/sites/default/files/Produkte_Range.jpg"
                        style={{
                          width: "699px",
                          height: "274px"
                        }}
                      />
                    </p>
                    <div className="nextbtn">
                      <a className="btn" href="/imodium-produkte/">
                        <span className="start">Produktübersicht</span>
                        <span className="end">&nbsp;</span>
                      </a>
                    </div>
                    <div className="clear">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
